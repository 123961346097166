import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { FormattedMessage } from 'gatsby-plugin-intl';

import FrontSection8 from 'components/FrontSection8';

import './News.scss';

const NewsTemplate = ({ pageContext }) => (
  <div className="news-page">
    {
        pageContext.topImage && (
          <div className="top-image">
            <img src={pageContext.topImage.src} alt={pageContext.topImage.alt} className="img-scaled-down" />
          </div>
        )
      }
    <div className="news-inner">
      <div className="heading small-section">
        <h1>
          {pageContext.title}
        </h1>
        <div className="inline mobile-inline description-text">
          <div>
            {pageContext.date}
          </div>
          <div className="author">
            <FormattedMessage id="author" />
            :
            <span>
              {pageContext.author}
            </span>
          </div>
        </div>
      </div>
      {
          pageContext.sections.map((el) => (
            <div
              className={classnames('section-wrapper', {
                'small-section': !el.image,
                'big-section': el.image,
              })}
              key={el.id}
            >
              <div
                className={classnames('item-wrapper', {
                  split: el.image,
                })}
              >
                {
                  el.image && (
                    <div className="width-50 image">
                      <img src={el.image.src} alt={el.image.alt} className="img-scaled-down" />
                    </div>
                  )
                }
                <div
                  className={classnames({
                    'width-50': el.image,
                  })}
                >
                  {
                    el.title && (
                      <h2 className="paragraph">
                        {el.title}
                      </h2>
                    )
                  }
                  {
                    el.description && (
                      <p
                        className="description-text paragraph"
                      >
                        {el.description}
                      </p>
                    )
                  }
                </div>
              </div>
            </div>
          ))
        }
    </div>
    <FrontSection8 hideId={pageContext.id} />
  </div>
);

NewsTemplate.propTypes = {
  pageContext: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default NewsTemplate;
