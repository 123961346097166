import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, Link } from 'gatsby-plugin-intl';

import newsJSON from 'content/news.json';

import './FrontSection8.scss';

const FrontSection8 = ({ hideId }) => (
  <div className="front-section-8 section-spacer" id="section-8">
    <div className="inline mobile-inline">
      {
            newsJSON.news.map((news) => news.id !== hideId && (
              <div className="size-50" key={`news-${news.id}`}>
                <h2 className="subtitle">
                  {news.title}
                </h2>
                <div className="part2 description-text">
                  {news.date}
                </div>
                <div className="part3 description-text">
                  {news.shortDesc}
                </div>
                <Link
                  className="btn button"
                  to={`/news/${news.id}`}
                >
                  <FormattedMessage id="more" />
                </Link>
              </div>
            ))
        }
    </div>
  </div>
);

FrontSection8.propTypes = {
  hideId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

FrontSection8.defaultProps = {
  hideId: null,
};

export default FrontSection8;
